import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class RoswellTeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.bobsteele.com/roswell-team/"}
        />
        <Grid
          className={"custom-page-roswell-stylist custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"palaqtmljjrsszwh"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"cjkkoaerkpxtrhst"}
              style={{ padding: 16 }}
              width={12}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "39c6716e-250f-4c11-9887-ce20691b91bc",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"xehelfzuggdlqlng"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"gtwfienvvnlwbonf"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "efe6a583-f532-487b-b5db-776a6aa436a2",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "263461a6-9e6a-44aa-8f61-fdfc0d83b0cd",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"rkodgqxfmpkjvcmi"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "a50cecc5-7523-4f0b-a302-82f164ea905e",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "e3f66034-29ce-4759-bbf5-97b9efa9dc21",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"iehfkoirndvithdd"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fa2dfbe8-54bf-4f66-8418-847be36633fa",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "ebfab173-9cc2-40ab-933c-1c452cab192d",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ftgwutoamhcxacbv"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"xwdcrfogprgriecf"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "d125c0db-305e-4fff-bdfc-bc0b2a2067da",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "e3c676b7-6741-48eb-bfd3-d231c4c09964",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"zwphbgcljvidkamn"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "2086a266-0337-4347-b66a-ae703e5c9300",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "30da6453-a53c-4710-80c7-b3268303268b",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"xfpjovqgwnwalcsm"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "73d253d1-a64d-4bcf-b106-1484731bb42c",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "25194b0d-c6a8-4aba-aa17-942e70fe0d56",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26951 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
